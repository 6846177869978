import LOAN_PURPOSES from '../../../../localizations/config/FI/shared/loan-purposes';
import EMPLOYMENT_TYPES from '../../../../localizations/config/FI/shared/employment-types';
import HOME_TYPES from '../../../../localizations/config/FI/shared/home-types';
import MARITAL_STATUS from '../../../../localizations/config/FI/shared/marital-status';
import DEPENDANTS from '../../../../localizations/config/FI/shared/dependants';
import EDUCATION_TYPES from '../../../../localizations/config/FI/shared/education-types';
import MILITARY_TYPES from '../../../../localizations/config/FI/shared/military-types';
import OCCUPATION_CATEGORY from '../../../../localizations/config/FI/shared/occupation-category';
import CITIZENSHIPS from '../../../../localizations/config/FI/shared/citizenships';
import HOME_TYPE_CATEGORIES from '../../../../localizations/config/FI/shared/home-type-categories';
import LOAN_TYPES from '../../../../localizations/config/FI/rahalaitos/loan-types';

import {
  mergeLoan,
  dependants,
  purpose,
  homeType,
  civilStatus,
  homeCost,
  onlyNumbers,
  numbersZeroAndEmpty,
  employerName,
  otherCost,
} from '../../../../validators';

import { validateMonthlySalaryBeforeTax, spouseMonthlyIncomeBeforeTaxValidator } from '../../../../validators/FI/monthlySalaryBeforeTax';
import email from '../../../../validators/FI/email';
import monthlySalaryAfterTax from '../../../../validators/FI/monthlySalaryAfterTax';
import otherIncomeBeforeTax from '../../../../validators/otherIncomeBeforeTax';
import { validateEmploymentSinceYear, validateEmploymentSinceMonth } from '../../../../validators/employedSince';
import validateMoveInDate from '../../../../validators/FI/validateMoveInDate';
import validatePhoneNumberFi from '../../../../validators/FI/phoneNumber';
import { validateEmploymentToYear, validateEmploymentToMonth } from '../../../../validators/employedTo';
import { validateRequiredButtonGroup, validateRequiredCheckbox } from '../../../../validators/requiredButtonGroup';
import validateRequiredTextInput from '../../../../validators/requiredTextInput';
import validateBankAccount from '../../../../validators/FI/bankAccount';
import validateNationalId from '../../../../validators/FI/nationalId';
import LOAN_LIMITS from '../../../../localizations/config/FI/shared/loan-limits';

import textResource from '../../../../utils/text-resources/rahalaitos/FI/messages';
import submit from '../../../../mappers/FI/application';

const tooltip = (text) => {
  const temp = {
    trigger: 'focus',
    text,
    width: 'fill',
    position: 'right',
  };
  return temp;
};

const dataLinks = {
  quickReapply: '/returning-customer/',
  quickReapplyEng: '/en/returning-customer/',
  userAgreementEng: 'https://www.rahalaitos.fi/en/user-agreement/',
  userAgreement: 'https://www.rahalaitos.fi/kayttajaehdot',
  dataPolicy: 'https://www.rahalaitos.fi/tietosuojaseloste',
  dataPolicyEng: 'https://www.rahalaitos.fi/en/data-protection-policy/',
};

const data = {
  general: {
    mapper: submit,
  },
  links: dataLinks,
  slides: [
    {
      title: '',
      coApplicantSlide: false,
      fields: [
        {
          component: 'StoreValueDisplay',
          props: {
            label: textResource.applicationLoanAmountLabel,
            value: 'totalAmount',
            fontWeight: 'bold',
            position: 'space-between',
            fontSize: '1rem',
            fieldDisplay: {
              name: 'totalAmount',
              type: 'tel',
              maxValue: 60000,
              suffix: textResource.loanCalculatorCurrencySuffix,
              placeholder: textResource.loanCalculatorTotalLoanAmountPlaceholder,
              validate: onlyNumbers,
              hasValidateFunction: true,
              isNumber: true,
              unstyled: true,
            },
            layout: {
              start: 1,
              end: 4,
              mobileOrder: '-1',
            },
          },
        },
        {
          component: 'StoreValueDisplay',
          props: {
            label: textResource.repaymentTimeLabel,
            value: 'repaymentTime',
            fontWeight: 'bold',
            position: 'space-between',
            fontSize: '1rem',
            fieldDisplay: {
              suffix: textResource.loanCalculatorRepaymentTimeSuffix,
              name: 'repaymentTime',
              type: 'tel',
              placeholder: textResource.repaymentTimePlaceholder,
              maxValue: 20,
              validate: onlyNumbers,
              validateOnRegister: true,
              hasValidateFunction: true,
              isNumber: true,
              unstyled: true,
            },
            layout: {
              start: 4,
              end: 7,
              mobileOrder: '0',
            },
          },
        },
        {
          component: 'ValidatedForm',
          form: 'Slider',
          props: {
            name: 'totalAmount',
            showButtons: true,
            type: 'tel',
            lead: true,
            sliderConfig: {
              range: {
                min: 500,
                max: 60000,
              },
              step: LOAN_LIMITS.STEP,
            },
            layout: {
              start: 1,
              end: 4,
              mobileOrder: '-1',
            },
          },
        },
        {
          component: 'ValidatedForm',
          form: 'Slider',
          props: {
            name: 'repaymentTime',
            showButtons: true,
            type: 'tel',
            lead: true,
            sliderConfig: {
              range: {
                min: 1,
                max: 20,
              },
            },
            layout: {
              start: 4,
              end: 7,
              mobileOrder: '0',
            },
          },
        },
        {
          component: 'MonthlyCost',
          props: {
            label: textResource.monthlyCostApproximateMonthlyCostLabel,
            approximateMonthlyCostMobile: textResource.approximateMonthlyCostMobile,
            repaymentTimeLimit: '>5',
            minimal: true,
            layout: {
              start: 1,
              end: 7,
            },
          },
        },
        {
          component: 'ConsolidationLoanAmountInput',
          props: {
            name: 'consolidationAmount',
            consolidationLabel: textResource.combinedLoanConsolidationAmount,
            hasValidateFunction: true,
            lead: true,
            showButtons: true,
            layout: {
              start: 1,
              end: 7,
            },
            visibleWhen: 'return (formState.values.merge === true);',
          },
        },
        {
          component: 'ValidatedForm',
          form: 'Checkbox',
          props: {
            name: 'merge',
            label: textResource.currentLoanMergeLabel,
            text: textResource.currentLoanMergeLabel,
            linkText: textResource.samblaPlusReadMore,
            layout: {
              start: 1,
              end: 7,
            },
          },
        },
        {
          component: 'ValidatedForm',
          form: 'Checkbox',
          props: {
            name: 'hasCoApplicant',
            label: textResource.addCoApplicantDesc,
            text: textResource.addCoApplicantDesc,
            layout: {
              start: 1,
              end: 7,
            },
          },
        },
        {
          component: 'Break',
          props: {
            shade: 'down',
            layout: {
              start: 1,
              end: 6,
            },
          },
        },
        {
          component: 'ValidatedForm',
          form: 'TextInput',
          sectionName: 'applicant',
          props: {
            name: 'phoneNumber',
            type: 'tel',
            label: textResource.phoneNumberLabel,
            placeholder: textResource.phoneNumberPlaceholder,
            hasValidateFunction: true,
            validate: validatePhoneNumberFi,
            lead: true,
            layout: {
              start: 1,
              end: 3,
            },
          },
        },
        {
          component: 'ValidatedForm',
          form: 'EmailInput',
          sectionName: 'applicant',
          props: {
            name: 'email',
            label: textResource.emailLabel,
            placeholder: textResource.emailPlaceholder,
            tooltip: tooltip(textResource.emailTooltip),
            hasValidateFunction: true,
            validate: email,
            lead: true,
            layout: {
              start: 3,
              end: 5,
            },
          },
        },
        {
          component: 'ValidatedForm',
          form: 'Select',
          sectionName: 'applicant',
          props: {
            name: 'employmentType',
            label: textResource.employmentTypeLabel,
            placeholder: textResource.employmentTypePlaceholder,
            tooltip: tooltip(textResource.employmentTypeTooltip),
            hasValidateFunction: true,
            validate: validateRequiredButtonGroup,
            options: EMPLOYMENT_TYPES,
            layout: {
              start: 5,
              end: 7,
            },
          },
        },
        {
          component: 'ValidatedForm',
          form: 'TextInput',
          sectionName: 'applicant',
          props: {
            name: 'monthlyIncomeBeforeTax',
            isNumber: true,
            type: 'tel',
            label: textResource.monthlyIncomeBeforeTaxLabel,
            placeholder: textResource.monthlyIncomeBeforeTaxPlaceholder,
            hasValidateFunction: true,
            validate: validateMonthlySalaryBeforeTax,
            layout: {
              start: 1,
              end: 3,
            },
          },
        },
        {
          component: 'ValidatedForm',
          form: 'TextInput',
          sectionName: 'applicant',
          props: {
            name: 'monthlyIncomeAfterTax',
            isNumber: true,
            type: 'tel',
            label: textResource.monthlyIncomeAfterTaxLabel,
            placeholder: textResource.monthlyIncomeAfterTaxPlaceholder,
            hasValidateFunction: true,
            validate: monthlySalaryAfterTax,
            layout: {
              start: 3,
              end: 5,
            },
          },
        },
        {
          component: 'StoreValueDisplay',
          props: {
            label: textResource.applicationSavedLabel,
            enabledPaths: ['preappsms', 'preappemail'],
            fontWeight: 'bold',
            position: 'left',
            background: 'var(--teal)',
            borderRadius: '10px',
            padding: '1rem',
            margin: '0 0 1rem 0',
            color: 'var(--white)',
            fontSize: '1rem',
            layout: {
              start: 1,
              end: 7,
            },
          },
        },
        {
          component: 'ValidatedForm',
          form: 'TextInput',
          sectionName: 'applicant',
          props: {
            name: 'nationalId',
            label: textResource.nationalIdLabel,
            placeholder: textResource.nationalIdPlaceholder,
            tooltip: tooltip(textResource.nationalIdTooltip),
            hasValidateFunction: true,
            validate: validateNationalId,
            lead: true,
            layout: {
              start: 1,
              end: 3,
            },
          },
        },
        {
          component: 'ValidatedForm',
          form: 'Select',
          sectionName: 'applicant',
          props: {
            name: 'citizenship',
            label: textResource.citizenshipLabel,
            placeholder: textResource.citizenshipPlaceholder,
            hasValidateFunction: true,
            validate: validateRequiredButtonGroup,
            options: CITIZENSHIPS,
            layout: {
              start: 3,
              end: 5,
            },
          },
        },
        {
          component: 'ValidatedForm',
          form: 'TextInput',
          props: {
            name: 'bankAccount',
            type: 'text',
            convertToUpperCase: true,
            label: textResource.bankAccountLabel,
            placeholder: textResource.bankAccountPlaceholder,
            tooltip: tooltip(textResource.bankAccountTooltip),
            hasValidateFunction: true,
            validate: validateBankAccount,
            layout: {
              start: 5,
              end: 7,
            },
          },
        },
        {
          component: 'ValidatedForm',
          form: 'Select',
          sectionName: 'applicant',
          props: {
            name: 'civilStatus',
            label: textResource.civilStatusLabel,
            placeholder: textResource.civilStatusPlaceholder,
            tooltip: tooltip(textResource.civilStatusTooltip),
            hasValidateFunction: true,
            validate: civilStatus,
            options: MARITAL_STATUS,
            layout: {
              start: 1,
              end: 3,
            },
          },
        },
        {
          component: 'ValidatedForm',
          form: 'Select',
          sectionName: 'applicant',
          props: {
            name: 'dependants',
            label: textResource.dependantsLabel,
            placeholder: textResource.dependantsPlaceholder,
            tooltip: tooltip(textResource.dependantsTooltip),
            hasValidateFunction: true,
            validate: dependants,
            options: DEPENDANTS,
            buttonLayout: 'compact',
            layout: {
              start: 3,
              end: 5,
            },
          },
        },
        {
          component: 'ValidatedForm',
          form: 'TextInput',
          sectionName: 'applicant',
          props: {
            name: 'spouseMonthlyIncomeBeforeTax',
            isNumber: true,
            type: 'tel',
            label: textResource.spouseMonthlyIncomeLabel,
            placeholder: textResource.spouseMonthlyIncomePlaceholder,
            hasValidateFunction: true,
            validate: spouseMonthlyIncomeBeforeTaxValidator,
            visibleWhen: 'return (formState.values.applicant.civilStatus && (formState.values.applicant.civilStatus === "married" || formState.values.applicant.civilStatus === "partner"));',
            layout: {
              start: 5,
              end: 7,
            },
          },
        },
        {
          component: 'ValidatedForm',
          form: 'Select',
          sectionName: 'applicant',
          props: {
            name: 'educationLevel',
            label: textResource.educationLabel,
            placeholder: textResource.educationPlaceholder,
            hasValidateFunction: true,
            validate: validateRequiredButtonGroup,
            options: EDUCATION_TYPES,
            layout: {
              start: 1,
              end: 3,
            },
          },
        },
        {
          component: 'ValidatedForm',
          form: 'Select',
          sectionName: 'applicant',
          props: {
            name: 'militaryServiceStatus',
            hasValidateFunction: true,
            validate: validateRequiredButtonGroup,
            label: textResource.militaryTypeLabel,
            placeholder: textResource.militaryTypePlaceholder,
            options: MILITARY_TYPES,
            layout: {
              start: 3,
              end: 5,
            },
          },
        },
        {
          component: 'ValidatedForm',
          form: 'Select',
          sectionName: 'applicant',
          props: {
            name: 'occupationCategory',
            label: textResource.occupationCategoryLabel,
            placeholder: textResource.occupationCategoryPlaceholder,
            hasValidateFunction: true,
            validate: validateRequiredButtonGroup,
            visibleWhen: 'return (formState.values.applicant.employmentType !== undefined && (formState.values.applicant.employmentType !== "disabilityPension" && formState.values.applicant.employmentType !== "retired" && formState.values.applicant.employmentType !== "student" && formState.values.applicant.employmentType !== "unemployed") );',
            options: OCCUPATION_CATEGORY,
            layout: {
              start: 5,
              end: 7,
            },
          },
        },
        {
          component: 'ValidatedForm',
          form: 'TextInput',
          sectionName: 'applicant',
          props: {
            name: 'employerName',
            label: textResource.employerNameLabel,
            dynamicLabel: [
              {
                key: 'employmentType',
                originalLabel: textResource.employerNameLabel,
                values: [
                  {
                    name: 'selfEmployed',
                    sectionName: 'applicant',
                    label: textResource.selfEmployedEmployerNameDynamicLabel,
                  },
                ],
              },
            ],
            placeholder: textResource.employerNamePlaceholder,
            tooltip: tooltip(textResource.employerNameTooltip),
            hasValidateFunction: true,
            validate: employerName,
            visibleWhen: 'return (formState.values.applicant.employmentType !== undefined && (formState.values.applicant.employmentType !== "disabilityPension" && formState.values.applicant.employmentType !== "retired" && formState.values.applicant.employmentType !== "student" && formState.values.applicant.employmentType !== "unemployed") );',
            layout: {
              start: 1,
              end: 3,
            },
          },
        },
        {
          component: 'ValidatedForm',
          form: 'TextInput',
          sectionName: 'applicant',
          props: {
            name: 'occupationTitle',
            label: textResource.occupationTitleLabel,
            placeholder: textResource.occupationTitlePlaceholder,
            hasValidateFunction: true,
            validate: validateRequiredTextInput,
            visibleWhen: 'return (formState.values.applicant.employmentType !== undefined && (formState.values.applicant.employmentType !== "disabilityPension" && formState.values.applicant.employmentType !== "retired" && formState.values.applicant.employmentType !== "student" && formState.values.applicant.employmentType !== "unemployed") );',
            layout: {
              start: 3,
              end: 5,
            },
          },
        },
        {
          component: 'EmploymentSinceInputYear',
          sectionName: 'applicant',
          props: {
            name: 'employmentSince',
            label: textResource.employmentSinceYearLabel,
            dynamicLabel: [
              {
                key: 'employmentType',
                originalLabel: textResource.employmentSinceYearLabel,
                values: [
                  {
                    name: 'selfEmployed',
                    sectionName: 'applicant',
                    label: textResource.selfEmployedSinceYearDynamicLabel,
                  },
                  {
                    name: 'student',
                    sectionName: 'applicant',
                    label: textResource.studentSinceYearDynamicLabel,
                  },
                  {
                    name: 'retired',
                    sectionName: 'applicant',
                    label: textResource.retiredSinceDynamicLabel,
                  },
                  {
                    name: 'disabilityPension',
                    sectionName: 'applicant',
                    label: textResource.disabilityPensionSinceDynamicLabel,
                  },
                  {
                    name: 'unemployed',
                    sectionName: 'applicant',
                    label: textResource.unemployedSinceDynamicLabel,
                  },
                ],
              },
            ],
            placeholder: textResource.employmentSinceYearPlaceholder,
            tooltip: tooltip(textResource.employmentSinceYearTooltip),
            hasValidateFunction: true,
            validate: validateEmploymentSinceYear,
            layout: {
              start: 1,
              end: 3,
            },
          },
        },
        {
          component: 'EmploymentSinceInputMonth',
          sectionName: 'applicant',
          props: {
            name: 'employmentSinceMonth',
            label: textResource.employmentSinceMonthLabel,
            dynamicLabel: [
              {
                key: 'employmentType',
                originalLabel: textResource.employmentSinceMonthLabel,
                values: [
                  {
                    name: 'selfEmployed',
                    sectionName: 'applicant',
                    label: textResource.selfEmployedSinceMonthDynamicLabel,
                  },
                  {
                    name: 'student',
                    sectionName: 'applicant',
                    label: textResource.studentSinceMonthDynamicLabel,
                  },
                  {
                    name: 'retired',
                    sectionName: 'applicant',
                    label: textResource.retiredSinceDynamicLabel,
                  },
                  {
                    name: 'disabilityPension',
                    sectionName: 'applicant',
                    label: textResource.disabilityPensionSinceDynamicLabel,
                  },
                ],
              },
            ],
            placeholder: textResource.employedSinceMonthPlaceholder,
            tooltip: tooltip(textResource.employmentSinceMonthTooltip),
            hasValidateFunction: true,
            validate: validateEmploymentSinceMonth,
            visibleWhen: 'return (formState.values.applicant.employmentSince > new Date().getFullYear() - 4);',
            layout: {
              start: 3,
              end: 5,
            },
          },
        },
        {
          component: 'ValidatedForm',
          form: 'TextInput',
          sectionName: 'applicant',
          props: {
            name: 'employmentTo',
            type: 'tel',
            label: textResource.employedToYearLabel,
            placeholder: textResource.employedToYearPlaceholder,
            tooltip: tooltip(textResource.employedToTooltip),
            hasValidateFunction: true,
            validate: validateEmploymentToYear,
            visibleWhen: 'return (formState.values.applicant.employmentType === "temporary" || formState.values.applicant.employmentType === "parentalLeave");',
            layout: {
              start: 1,
              end: 3,
            },
          },
        },
        {
          component: 'EmploymentSinceInputMonth',
          sectionName: 'applicant',
          props: {
            name: 'employmentToMonth',
            label: textResource.employedToMonthLabel,
            placeholder: textResource.employedToMonthPlaceholder,
            hasValidateFunction: true,
            validate: validateEmploymentToMonth,
            visibleWhen: 'return (formState.values.applicant.employmentType === "temporary" || formState.values.coApplicant.employmentType === "parentalLeave");',
            layout: {
              start: 3,
              end: 5,
            },
          },
        },

        {
          component: 'ValidatedForm',
          form: 'ButtonGroup',
          sectionName: 'applicant',
          props: {
            name: 'otherIncome',
            label: textResource.otherIncomeLabel,
            hasValidateFunction: true,
            validate: mergeLoan,
            labelLayout: 'center; margin-right: 0;',
            buttonLayout: 'toggle',
            buttons: [
              {
                label: textResource.mergeLoanYes,
                value: 'true',
              },
              {
                label: textResource.mergeLoanNo,
                value: 'false',
              },
            ],
            tooltip: tooltip(textResource.otherIncomeTooltip),
            layout: {
              start: 1,
              end: 7,
            },
          },
        },
        {
          component: 'ValidatedForm',
          form: 'TextInput',
          sectionName: 'applicant',
          props: {
            name: 'otherMonthlyIncomeBeforeTax',
            isNumber: true,
            type: 'tel',
            label: textResource.grossOtherIncomeLabel,
            placeholder: textResource.grossOtherIncomePlaceholder,
            hasValidateFunction: true,
            validate: otherIncomeBeforeTax,
            visibleWhen: 'return (formState.values.applicant.otherIncome !== undefined && formState.values.applicant.otherIncome === true);',
            layout: {
              start: 1,
              end: 3,
            },
          },
        },
        {
          component: 'ValidatedForm',
          form: 'Select',
          sectionName: 'applicant',
          props: {
            name: 'homeType',
            label: textResource.homeTypeLabel,
            placeholder: textResource.homeTypePlaceholder,
            tooltip: tooltip(textResource.homeTypeTooltip),
            hasValidateFunction: true,
            validate: homeType,
            options: HOME_TYPES,
            layout: {
              start: 1,
              end: 3,
            },
          },
        },
        {
          component: 'ValidatedForm',
          form: 'Select',
          sectionName: 'applicant',
          props: {
            name: 'homeTypeCategory',
            label: textResource.homeTypeCategoryDescriptionLabel,
            placeholder: textResource.homeTypeCategoryDescriptionPlaceholder,
            hasValidateFunction: true,
            validate: validateRequiredButtonGroup,
            options: HOME_TYPE_CATEGORIES,
            layout: {
              start: 3,
              end: 5,
            },
          },
        },
        {
          component: 'EmploymentSinceInputYear',
          sectionName: 'applicant',
          props: {
            name: 'moveInDate',
            label: textResource.moveInDateLabel,
            placeholder: textResource.moveInDatePlaceholder,
            hasValidateFunction: true,
            validate: validateMoveInDate,
            layout: {
              start: 5,
              end: 7,
            },
          },
        },
        {
          component: 'ValidatedForm',
          form: 'TextInput',
          sectionName: 'applicant',
          props: {
            name: 'homeCost',
            isNumber: true,
            type: 'tel',
            label: textResource.homeCostLabel,
            placeholder: textResource.homeCostPlaceholder,
            tooltip: tooltip(textResource.homeCostTooltip),
            hasValidateFunction: true,
            validate: homeCost,
            layout: {
              start: 1,
              end: 3,
            },
          },
        },
        {
          component: 'ValidatedForm',
          form: 'TextInput',
          sectionName: 'applicant',
          props: {
            name: 'otherLivingCost',
            label: textResource.otherLivingCostLabel,
            placeholder: textResource.otherLivingCostPlaceholder,
            tooltip: tooltip(textResource.otherLivingCostTooltip),
            hasValidateFunction: true,
            validate: otherCost,
            layout: {
              start: 3,
              end: 5,
            },
          },
        },
        {
          component: 'ValidatedForm',
          form: 'Select',
          props: {
            name: 'purpose',
            label: textResource.purposeHeaderText,
            placeholder: textResource.purposeHeaderPlaceholder,
            tooltip: tooltip(textResource.purposeTooltip),
            hasValidateFunction: true,
            validate: purpose,
            visibleWhen: 'return (formState.values.merge === false || parseInt(formState.values.totalAmount) > parseInt(formState.values.consolidationAmount));',
            options: LOAN_PURPOSES,
            lead: true,
            layout: {
              start: 5,
              end: 7,
            },
          },
        },
        {
          component: 'ValidatedForm',
          sectionName: 'applicant',
          form: 'ButtonGroup',
          props: {
            name: 'politicallyExposed',
            label: textResource.isPepLabel,
            labelLayout: 'center; margin-right: 0;',
            tooltip: tooltip(textResource.isPepTooltip),
            buttonLayout: 'toggle',
            rightPadding: true,
            buttons: [
              {
                label: textResource.mergeLoanYes,
                value: 'true',
              },
              {
                label: textResource.mergeLoanNo,
                value: 'undefined',
              },
            ],
            layout: {
              start: 1,
              end: 7,
            },
          },
        },
        {
          component: 'FormHeadline',
          props: {
            label: textResource.debtBreakdownLabel,
            layout: {
              start: 1,
              end: 7,
            },
          },
        },
        {
          component: 'ExpandingGridList',
          props: {
            name: 'currentLoanList',
            sectionName: 'applicant',
            addButtonLabel: textResource.loanAdd,
            columns: [
              {
                component: 'Select',
                name: 'type',
                placeholder: textResource.creditCardPlaceholder,
                hasValidateFunction: true,
                label: textResource.loanTypeLabel,
                validate: validateRequiredButtonGroup,
                options: LOAN_TYPES,
              },
              {
                component: 'TextInput',
                name: 'amount',
                isNumber: true,
                type: 'tel',
                hasValidateFunction: true,
                label: textResource.loanAmountLabel,
                validate: numbersZeroAndEmpty,
                placeholder: textResource.applicationLoanAmountPlaceholder,
                tooltip: tooltip(textResource.loanAmountTooltip),
              },
              {
                component: 'TextInput',
                name: 'monthlyPayment',
                isNumber: true,
                type: 'tel',
                hasValidateFunction: true,
                label: textResource.loanMonthlyCostLabel,
                validate: numbersZeroAndEmpty,
                placeholder: textResource.applicationLoanAmountPlaceholder,
                tooltip: tooltip(textResource.loanMonthlyCostTooltip),
              },
              {
                component: 'Checkbox',
                name: 'transfer',
                label: textResource.loanTransferLabel,
                internalVisibleWhen: {
                  key: 'type',
                  values: ['consumer', 'creditCard'],
                  initialValue: false,
                },
              },
              {
                component: 'Select',
                name: 'loanShare',
                label: textResource.houseLoansPartLabel,
                hasValidateFunction: true,
                placeholder: textResource.civilStatusPlaceholder,
                internalVisibleWhen: {
                  key: 'type',
                  values: ['mortgage', 'car'],
                },
                options: [
                  {
                    label: textResource.houseLoansLoanIsOwnedByApplicant,
                    value: '100',
                  },
                  {
                    label: textResource.houseLoansLoanIsSharedWithCoApplicant,
                    value: '50',
                  },
                ],
              },
            ],
            layout: {
              start: 1,
              end: 7,
            },
          },
        },
        {
          component: 'SimpleValueLabel',
          props: {
            suffix: textResource.loanCalculatorCurrencySuffix,
            value: 'applicant.currentLoans.refinanceSum',
            label: textResource.loansSummary,
            layout: {
              start: 1,
              end: 3,
            },
          },
        },
        {
          component: 'ValidatedForm',
          form: 'ButtonGroup',
          sectionName: 'applicant',
          props: {
            name: 'hasCreditCard',
            label: textResource.creditCardLabel,
            labelLayout: 'center; margin-right: 0;',
            tooltip: tooltip(textResource.creditCardTooltip),
            hasValidateFunction: true,
            validate: validateRequiredButtonGroup,
            buttonLayout: 'toggle',
            buttons: [
              {
                label: textResource.mergeLoanYes,
                value: 'true',
              },
              {
                label: textResource.mergeLoanNo,
                value: 'false',
              },
            ],
            layout: {
              start: 1,
              end: 7,
            },
          },
        },
        {
          component: 'FormTextBlock',
          props: {
            text: textResource.otherLoansInfoMessage,
            visibleWhen: 'return (Object.keys(formState.values.applicant.currentLoans)?.length > 1);',
            transparent: true,
            margin: true,
            special: true,
            layout: {
              start: 1,
              end: 7,
            },
          },
        },
        {
          component: 'StoreValueDisplay',
          props: {
            label: textResource.coApplicantLabel,
            fontWeight: 'bold',
            position: 'left',
            background: 'var(--teal)',
            borderRadius: '10px',
            padding: '1rem',
            visibleWhen: 'return (formState.values.hasCoApplicant);',
            margin: '0 0 1rem 0',
            color: 'var(--white)',
            layout: {
              start: 1,
              end: 7,
            },
          },
        },
        {
          component: 'ValidatedForm',
          form: 'TextInput',
          sectionName: 'coApplicant',
          props: {
            name: 'phoneNumber',
            type: 'tel',
            label: textResource.coApplicantPhoneNumberLabel,
            placeholder: textResource.coApplicantPhoneNumberPlaceholder,
            tooltip: tooltip(textResource.coApplicantPhoneNumberTooltip),
            hasValidateFunction: true,
            validate: validatePhoneNumberFi,
            visibleWhen: 'return (formState.values.hasCoApplicant);',
            layout: {
              start: 1,
              end: 3,
            },
          },
        },
        {
          component: 'ValidatedForm',
          form: 'EmailInput',
          sectionName: 'coApplicant',
          props: {
            name: 'email',
            label: textResource.coApplicantEmailLabel,
            placeholder: textResource.coApplicantEmailPlaceholder,
            tooltip: tooltip(textResource.coApplicantEmailTooltip),
            validate: email,
            visibleWhen: 'return (formState.values.hasCoApplicant);',
            layout: {
              start: 3,
              end: 5,
            },
          },
        },
        {
          component: 'ValidatedForm',
          form: 'TextInput',
          sectionName: 'coApplicant',
          props: {
            name: 'nationalId',
            type: 'tel',
            label: textResource.coApplicantNationalIdLabel,
            placeholder: textResource.coApplicantNationalIdPlaceholder,
            tooltip: tooltip(textResource.coApplicantNationalIdTooltip),
            hasValidateFunction: true,
            validate: validateNationalId,
            visibleWhen: 'return (formState.values.hasCoApplicant);',
            layout: {
              start: 5,
              end: 7,
            },
          },
        },
        {
          component: 'ValidatedForm',
          form: 'Select',
          sectionName: 'coApplicant',
          props: {
            name: 'citizenship',
            label: textResource.coApplicantCitizenshipLabel,
            placeholder: textResource.coApplicantCitizenshipPlaceholder,
            hasValidateFunction: true,
            validate: validateRequiredButtonGroup,
            options: CITIZENSHIPS,
            visibleWhen: 'return (formState.values.hasCoApplicant);',
            layout: {
              start: 1,
              end: 3,
            },
          },
        },
        {
          component: 'ValidatedForm',
          form: 'Select',
          sectionName: 'coApplicant',
          props: {
            name: 'educationLevel',
            label: textResource.coApplicantEducationLabel,
            placeholder: textResource.coApplicantEducationPlaceholder,
            hasValidateFunction: true,
            validate: validateRequiredButtonGroup,
            options: EDUCATION_TYPES,
            visibleWhen: 'return (formState.values.hasCoApplicant);',
            layout: {
              start: 3,
              end: 5,
            },
          },
        },
        {
          component: 'ValidatedForm',
          form: 'Select',
          sectionName: 'coApplicant',
          props: {
            name: 'militaryServiceStatus',
            label: textResource.coApplicantMilitaryTypeLabel,
            placeholder: textResource.coApplicantMilitaryTypePlaceholder,
            hasValidateFunction: true,
            validate: validateRequiredButtonGroup,
            options: MILITARY_TYPES,
            visibleWhen: 'return (formState.values.hasCoApplicant);',
            layout: {
              start: 5,
              end: 7,
            },
          },
        },
        {
          component: 'ValidatedForm',
          form: 'Select',
          sectionName: 'coApplicant',
          props: {
            name: 'civilStatus',
            label: textResource.coApplicantCivilStatusLabel,
            placeholder: textResource.coApplicantCivilStatusPlaceholder,
            tooltip: tooltip(textResource.coApplicantCivilStatusTooltip),
            hasValidateFunction: true,
            validate: civilStatus,
            options: MARITAL_STATUS,
            visibleWhen: 'return (formState.values.hasCoApplicant);',
            layout: {
              start: 1,
              end: 3,
            },
          },
        },
        {
          component: 'ValidatedForm',
          form: 'Select',
          sectionName: 'coApplicant',
          props: {
            name: 'dependants',
            label: textResource.coApplicantDependantsLabel,
            placeholder: textResource.coApplicantDependantsPlaceholder,
            tooltip: tooltip(textResource.coApplicantDependantsTooltip),
            hasValidateFunction: true,
            validate: dependants,
            options: DEPENDANTS,
            visibleWhen: 'return (formState.values.hasCoApplicant);',
            layout: {
              start: 3,
              end: 5,
            },
          },
        },
        {
          component: 'ValidatedForm',
          form: 'TextInput',
          sectionName: 'coApplicant',
          props: {
            isNumber: true,
            name: 'spouseMonthlyIncomeBeforeTax',
            type: 'tel',
            label: textResource.spouseMonthlyIncomeLabel,
            placeholder: textResource.spouseMonthlyIncomePlaceholder,
            hasValidateFunction: true,
            validate: spouseMonthlyIncomeBeforeTaxValidator,
            visibleWhen: 'return (formState.values.hasCoApplicant && formState.values.hasCoApplicant && formState.values.coApplicant.civilStatus && (formState.values.coApplicant.civilStatus === "married" || formState.values.coApplicant.civilStatus === "partner"));',
            layout: {
              start: 5,
              end: 7,
            },
          },
        },
        {
          component: 'ValidatedForm',
          form: 'Select',
          sectionName: 'coApplicant',
          props: {
            name: 'employmentType',
            label: textResource.coApplicantEmploymentTypeLabel,
            placeholder: textResource.coApplicantEmploymentTypePlaceholder,
            tooltip: tooltip(textResource.coApplicantEmploymentTypeTooltip),
            hasValidateFunction: true,
            validate: validateRequiredButtonGroup,
            options: EMPLOYMENT_TYPES,
            visibleWhen: 'return (formState.values.hasCoApplicant);',
            layout: {
              start: 1,
              end: 3,
            },
          },
        },
        {
          component: 'EmploymentSinceInputYear',
          sectionName: 'coApplicant',
          props: {
            name: 'employmentSince',
            label: textResource.coApplicantEmploymentSinceYearLabel,
            dynamicLabel: [
              {
                key: 'employmentType',
                originalLabel: textResource.coApplicantEmploymentSinceYearLabel,
                values: [
                  {
                    name: 'selfEmployed',
                    sectionName: 'coApplicant',
                    label: textResource.selfEmployedSinceYearDynamicLabel,
                  },
                  {
                    name: 'student',
                    sectionName: 'coApplicant',
                    label: textResource.studentSinceDynamicLabel,
                  },
                  {
                    name: 'retired',
                    sectionName: 'coApplicant',
                    label: textResource.retiredSinceDynamicLabel,
                  },
                  {
                    name: 'disabilityPension',
                    sectionName: 'coApplicant',
                    label: textResource.disabilityPensionSinceDynamicLabel,
                  },
                  {
                    name: 'unemployed',
                    sectionName: 'coApplicant',
                    label: textResource.unemployedSinceDynamicLabel,
                  },
                ],
              },
            ],
            placeholder: textResource.coApplicantEmploymentSinceYearPlaceholder,
            tooltip: tooltip(textResource.coApplicantEmploymentSinceYearTooltip),
            hasValidateFunction: true,
            validate: validateEmploymentSinceYear,
            visibleWhen: 'return (formState.values.hasCoApplicant);',
            layout: {
              start: 3,
              end: 5,
            },
          },
        },
        {
          component: 'EmploymentSinceInputMonth',
          sectionName: 'coApplicant',
          props: {
            name: 'employmentSinceMonth',
            dynamicLabel: [
              {
                key: 'employmentType',
                originalLabel: textResource.coApplicantEmploymentSinceMonthLabel,
                values: [
                  {
                    name: 'selfEmployed',
                    sectionName: 'coApplicant',
                    label: textResource.selfEmployedSinceMonthDynamicLabel,
                  },
                  {
                    name: 'student',
                    sectionName: 'coApplicant',
                    label: textResource.studentSinceMonthDynamicLabel,
                  },
                  {
                    name: 'retired',
                    sectionName: 'coApplicant',
                    label: textResource.retiredSinceMonthDynamicLabel,
                  },
                  {
                    name: 'disabilityPension',
                    sectionName: 'coApplicant',
                    label: textResource.disabilityPensionSinceMonthDynamicLabel,
                  },
                ],
              },
            ],
            label: textResource.coApplicantEmploymentSinceMonthLabel,
            placeholder: textResource.coApplicantEmploymentSinceMonthPlaceholder,
            tooltip: tooltip(textResource.coApplicantEmploymentSinceMonthTooltip),
            hasValidateFunction: true,
            validate: validateEmploymentSinceMonth,
            visibleWhen: 'return (formState.values.hasCoApplicant && formState.values.coApplicant.employmentSince > new Date().getFullYear() - 4);',
            layout: {
              start: 5,
              end: 7,
            },
          },
        },
        {
          component: 'ValidatedForm',
          form: 'TextInput',
          sectionName: 'coApplicant',
          props: {
            name: 'employerName',
            label: textResource.coApplicantEmployerNameLabel,
            placeholder: textResource.coApplicantEmployerNamePlaceholder,
            tooltip: tooltip(textResource.coApplicantEmployerNameTooltip),
            hasValidateFunction: true,
            validate: employerName,
            visibleWhen: 'return (formState.values.hasCoApplicant && formState.values.coApplicant.employmentType !== undefined && formState.values.coApplicant.employmentType !== "disabilityPension" && formState.values.coApplicant.employmentType !== "retired");',
            layout: {
              start: 1,
              end: 3,
            },
          },
        },
        {
          component: 'ValidatedForm',
          form: 'Select',
          sectionName: 'coApplicant',
          props: {
            name: 'occupationCategory',
            label: textResource.coApplicantOccupationLabel,
            placeholder: textResource.coApplicantOccupationPlaceholder,
            hasValidateFunction: true,
            validate: validateRequiredButtonGroup,
            visibleWhen: 'return (formState.values.hasCoApplicant && formState.values.coApplicant.employmentType !== undefined && (formState.values.coApplicant.employmentType !== "disabilityPension" && formState.values.coApplicant.employmentType !== "retired" && formState.values.coApplicant.employmentType !== "student" && formState.values.coApplicant.employmentType !== "unemployed") );',
            options: OCCUPATION_CATEGORY,
            layout: {
              start: 3,
              end: 5,
            },
          },
        },
        {
          component: 'ValidatedForm',
          form: 'TextInput',
          sectionName: 'coApplicant',
          props: {
            isNumber: true,
            type: 'tel',
            name: 'monthlyIncomeBeforeTax',
            label: textResource.coApplicantMonthlyIncomeBeforeTaxLabel,
            placeholder: textResource.coApplicantMonthlyIncomeBeforeTaxPlaceholder,
            tooltip: tooltip(textResource.coApplicantMonthlyIncomeBeforeTaxTooltip),
            hasValidateFunction: true,
            validate: validateMonthlySalaryBeforeTax,
            visibleWhen: 'return (formState.values.hasCoApplicant);',
            layout: {
              start: 1,
              end: 3,
            },
          },
        },
        {
          component: 'ValidatedForm',
          form: 'TextInput',
          sectionName: 'coApplicant',
          props: {
            name: 'monthlyIncomeAfterTax',
            isNumber: true,
            type: 'tel',
            label: textResource.coApplicantMonthlyIncomeAfterTaxLabel,
            placeholder: textResource.coApplicantMonthlyIncomeAfterTaxPlaceholder,
            hasValidateFunction: true,
            validate: monthlySalaryAfterTax,
            visibleWhen: 'return (formState.values.hasCoApplicant);',
            layout: {
              start: 3,
              end: 5,
            },
          },
        },
        {
          component: 'ValidatedForm',
          form: 'TextInput',
          sectionName: 'coApplicant',
          props: {
            name: 'occupationTitle',
            label: textResource.coApplicantOccupationTitleLabel,
            placeholder: textResource.occupationTitlePlaceholder,
            hasValidateFunction: true,
            validate: validateRequiredTextInput,
            visibleWhen: 'return (formState.values.hasCoApplicant && formState.values.coApplicant.employmentType !== undefined && (formState.values.coApplicant.employmentType !== "disabilityPension" && formState.values.coApplicant.employmentType !== "retired" && formState.values.coApplicant.employmentType !== "student" && formState.values.coApplicant.employmentType !== "unemployed") );',
            layout: {
              start: 5,
              end: 7,
            },
          },
        },
        {
          component: 'ValidatedForm',
          form: 'TextInput',
          sectionName: 'coApplicant',
          props: {
            name: 'employmentTo',
            type: 'tel',
            label: textResource.coApplicantEmployedToYearLabel,
            placeholder: textResource.coApplicantEmployedToYearPlaceholder,
            tooltip: tooltip(textResource.coApplicantEmployedToTooltip),
            hasValidateFunction: true,
            validate: validateEmploymentToYear,
            visibleWhen: 'return (formState.values.hasCoApplicant && formState.values.coApplicant.employmentType === "temporary" || formState.values.coApplicant.employmentType === "parentalLeave");',
            layout: {
              start: 1,
              end: 3,
            },
          },
        },
        {
          component: 'EmploymentSinceInputMonth',
          sectionName: 'coApplicant',
          props: {
            name: 'employmentToMonth',
            label: textResource.coApplicantEmployedToMonthLabel,
            placeholder: textResource.employedToMonthPlaceholder,
            hasValidateFunction: true,
            validate: validateEmploymentToMonth,
            visibleWhen: 'return (formState.values.hasCoApplicant && formState.values.coApplicant.employmentType === "temporary" || formState.values.coApplicant.employmentType === "parentalLeave");',
            layout: {
              start: 3,
              end: 5,
            },
          },
        },
        {
          component: 'ValidatedForm',
          form: 'ButtonGroup',
          sectionName: 'coApplicant',
          props: {
            name: 'otherIncome',
            label: textResource.coApplicantOtherIncomeLabel,
            labelLayout: 'center; margin-right: 0;',
            tooltip: tooltip(textResource.coApplicantOtherIncomeTooltip),
            hasValidateFunction: true,
            validate: mergeLoan,
            buttonLayout: 'toggle',
            visibleWhen: 'return (formState.values.hasCoApplicant);',
            buttons: [
              {
                label: textResource.mergeLoanYes,
                value: 'true',
              },
              {
                label: textResource.mergeLoanNo,
                value: 'false',
              },
            ],
            layout: {
              start: 1,
              end: 7,
            },
          },
        },
        {
          component: 'ValidatedForm',
          form: 'TextInput',
          sectionName: 'coApplicant',
          props: {
            name: 'otherMonthlyIncomeBeforeTax',
            isNumber: true,
            type: 'tel',
            label: textResource.coApplicantGrossOtherIncomeLabel,
            placeholder: textResource.coApplicantGrossOtherIncomePlaceholder,
            hasValidateFunction: true,
            validate: otherIncomeBeforeTax,
            visibleWhen: 'return (formState.values.hasCoApplicant && formState.values.coApplicant.otherIncome !== undefined && formState.values.coApplicant.otherIncome === true);',
            layout: {
              start: 1,
              end: 3,
            },
          },
        },
        {
          component: 'ValidatedForm',
          form: 'Select',
          sectionName: 'coApplicant',
          props: {
            name: 'homeType',
            label: textResource.coApplicantHomeTypeLabel,
            placeholder: textResource.coApplicantHomeTypePlaceholder,
            tooltip: tooltip(textResource.coApplicantHomeTypeTooltip),
            hasValidateFunction: true,
            validate: homeType,
            options: HOME_TYPES,
            visibleWhen: 'return (formState.values.hasCoApplicant);',
            layout: {
              start: 1,
              end: 3,
            },
          },
        },
        {
          component: 'ValidatedForm',
          form: 'Select',
          sectionName: 'coApplicant',
          props: {
            name: 'homeTypeCategory',
            label: textResource.coApplicantHomeTypeCategoryDescriptionLabel,
            hasValidateFunction: true,
            placeholder: textResource.coApplicantHomeTypeCategoryDescriptionLabel,
            validate: validateRequiredButtonGroup,
            options: HOME_TYPE_CATEGORIES,
            visibleWhen: 'return (formState.values.hasCoApplicant);',
            layout: {
              start: 3,
              end: 5,
            },
          },
        },
        {
          component: 'EmploymentSinceInputYear',
          sectionName: 'coApplicant',
          props: {
            name: 'moveInDate',
            label: textResource.moveInDateLabelCoApplicant,
            placeholder: textResource.moveInDatePlaceholderCoApplicant,
            hasValidateFunction: true,
            validate: validateMoveInDate,
            visibleWhen: 'return (formState.values.hasCoApplicant);',
            layout: {
              start: 5,
              end: 7,
            },
          },
        },
        {
          component: 'ValidatedForm',
          form: 'TextInput',
          sectionName: 'coApplicant',
          props: {
            name: 'homeCost',
            isNumber: true,
            type: 'tel',
            label: textResource.coApplicantHomeCostLabel,
            placeholder: textResource.coApplicantHomeCostPlaceholder,
            tooltip: tooltip(textResource.coApplicantHomeCostTooltip),
            hasValidateFunction: true,
            validate: homeCost,
            visibleWhen: 'return (formState.values.hasCoApplicant);',
            layout: {
              start: 1,
              end: 3,
            },
          },
        },
        {
          component: 'ValidatedForm',
          form: 'TextInput',
          sectionName: 'coApplicant',
          props: {
            name: 'otherLivingCost',
            label: textResource.coApplicantOtherLivingCostsLabel,
            placeholder: textResource.coApplicantOtherLivingCostPlaceholder,
            visibleWhen: 'return (formState.values.hasCoApplicant);',
            tooltip: tooltip(textResource.coApplicantOtherLivingCostsTooltip),
            hasValidateFunction: true,
            validate: otherCost,
            layout: {
              start: 3,
              end: 5,
            },
          },
        },
        {
          component: 'FormHeadline',
          props: {
            label: textResource.debtBreakdownLabel,
            coApp: true,
            visibleWhen: 'return (formState.values.hasCoApplicant);',
            layout: {
              start: 1,
              end: 7,
            },
          },
        },
        {
          component: 'ExpandingGridList',
          props: {
            name: 'currentLoanList',
            sectionName: 'coApplicant',
            visibleWhen: 'return (formState.values.hasCoApplicant);',
            addButtonLabel: textResource.loanAdd,
            columns: [
              {
                component: 'Select',
                name: 'type',
                placeholder: textResource.creditCardPlaceholder,
                hasValidateFunction: true,
                label: textResource.loanTypeLabel,
                validate: validateRequiredButtonGroup,
                options: LOAN_TYPES,
              },
              {
                component: 'TextInput',
                name: 'amount',
                isNumber: true,
                type: 'tel',
                hasValidateFunction: true,
                label: textResource.loanAmountLabel,
                validate: numbersZeroAndEmpty,
                placeholder: textResource.applicationLoanAmountPlaceholder,
                tooltip: tooltip(textResource.coApplicantLoanAmountTooltip),
              },
              {
                component: 'TextInput',
                name: 'monthlyPayment',
                isNumber: true,
                type: 'tel',
                hasValidateFunction: true,
                label: textResource.loanMonthlyCostLabel,
                validate: numbersZeroAndEmpty,
                placeholder: textResource.applicationLoanAmountPlaceholder,
                tooltip: tooltip(textResource.coApplicantLoanMonthlyCostTooltip),
              },
              {
                component: 'Checkbox',
                name: 'transfer',
                label: textResource.loanTransferLabel,
                internalVisibleWhen: {
                  key: 'type',
                  values: ['consumer', 'creditCard'],
                  initialValue: false,
                },
              },
              {
                component: 'Select',
                name: 'loanShare',
                label: textResource.houseLoansPartLabel,
                hasValidateFunction: true,
                placeholder: textResource.civilStatusPlaceholder,
                internalVisibleWhen: {
                  key: 'type',
                  values: ['mortgage', 'car'],
                },
                options: [
                  {
                    label: textResource.houseLoansLoanIsOwnedByApplicant,
                    value: '100',
                  },
                  {
                    label: textResource.houseLoansLoanIsSharedWithCoApplicant,
                    value: '50',
                  },
                ],
              },
            ],
            layout: {
              start: 1,
              end: 7,
            },
          },
        },
        {
          component: 'SimpleValueLabel',
          props: {
            visibleWhen: 'return (formState.values.hasCoApplicant);',
            suffix: textResource.loanCalculatorCurrencySuffix,
            value: 'coApplicant.currentLoans.refinanceSum',
            label: textResource.loansSummary,
            layout: {
              start: 1,
              end: 2,
            },
          },
        },
        {
          component: 'ValidatedForm',
          form: 'ButtonGroup',
          sectionName: 'coApplicant',
          props: {
            name: 'hasCreditCard',
            label: textResource.coApplicantCreditCardLabel,
            labelLayout: 'center; margin-right: 0;',
            tooltip: tooltip(textResource.coApplicantCreditCardTooltip),
            hasValidateFunction: true,
            validate: validateRequiredButtonGroup,
            buttonLayout: 'toggle',
            visibleWhen: 'return (formState.values.hasCoApplicant);',
            buttons: [
              {
                label: textResource.mergeLoanYes,
                value: 'true',
              },
              {
                label: textResource.mergeLoanNo,
                value: 'false',
              },
            ],
            layout: {
              start: 1,
              end: 7,
            },
          },
        },
        {
          component: 'ValidatedForm',
          sectionName: 'coApplicant',
          form: 'ButtonGroup',
          props: {
            name: 'politicallyExposedPerson',
            rightPadding: true,
            label: textResource.isCoApplicantPepLabel,
            tooltip: tooltip(textResource.isCoApplicantPepTooltip),
            visibleWhen: 'return (formState.values.hasCoApplicant);',
            buttons: [
              {
                label: textResource.mergeLoanYes,
                value: 'true',
              },
              {
                label: textResource.mergeLoanNo,
                value: 'undefined',
              },
            ],
            layout: {
              start: 1,
              end: 7,
            },
          },
        },
        {
          component: 'AutoAccept',
          props: {
            name: 'autoAccept',
            layout: {
              start: 1,
              end: 7,
            },
          },
        },
        {
          component: 'CreditCheckConsent',
          form: 'Checkbox',
          props: {
            name: 'creditCheckConsent',
            text: textResource.acceptCreditCheck,
            linkText: textResource.samblaPlusReadMore,
            links: 'https://www.rahalaitos.fi/kyselyjarjestelma',
            hasValidateFunction: true,
            validate: validateRequiredCheckbox,
            layout: {
              start: 1,
              end: 7,
            },
          },
        },
        {
          component: 'AcceptAgreement',
          form: 'Checkbox',
          props: {
            name: 'acceptAgreement',
            text: textResource.acceptAgreement,
            linkText: textResource.termsAndIntegrityPolicyLinksIntegrityPolicy,
            linkTextPolicy: textResource.termsAndIntegrityPolicyLinksTerms,
            linkDataPolicy: {
              FI: dataLinks.dataPolicy,
              EN: dataLinks.dataPolicyEng,
            },
            linkUserAgreement: {
              FI: dataLinks.userAgreement,
              EN: dataLinks.userAgreementEng,
            },
            hasValidateFunction: true,
            validate: validateRequiredCheckbox,
            linkInText: true,
            layout: {
              start: 1,
              end: 5,
            },
          },
        },
        {
          component: 'AcceptNewsLetter',
          form: 'Checkbox',
          props: {
            name: 'acceptNewsletter',
            text: textResource.acceptNewsletterConsentText,
            layout: {
              start: 1,
              end: 5,
            },
          },
        },
        {
          component: 'FormTextBlock',
          props: {
            text: textResource.pCRText,
            transparent: true,
            margin: false,
            center: false,
            PCR: true,
            layout: {
              start: 1,
              end: 7,
            },
          },
        },
        {
          component: 'SubmitButton',
          sectionName: 'applicant',
          props: {
            label: textResource.applyLabel,
            ctaBtnStyle: true,
            messages: {
              uncaughtSubmitError: textResource.submitErrorUncaught,
              generalSubmitError: textResource.submitErrorGeneral,
            },
            layout: {
              start: 7,
              end: 1,
            },
          },
        },
      ],
    },
  ],
};

export default data;
