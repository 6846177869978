import stripDelimiter from '../../utils/strip-delimiter-from-currency';
import { submitApplicant, submitCoApplicant } from './applicant';

const calculateNewLoan = (
  application,
) => {
  const totalAmount = parseInt(stripDelimiter(application.totalAmount), 10);
  const consolidationAmount = parseInt(stripDelimiter(application.consolidationAmount), 10);
  let newLoanAmount;
  const onlyNewLoan = (!application.merge);

  if ((consolidationAmount === totalAmount) && application.merge) {
    newLoanAmount = 0;
  } else if (onlyNewLoan) {
    newLoanAmount = totalAmount;
  } else {
    newLoanAmount = totalAmount - consolidationAmount;
  }

  return newLoanAmount;
};

const definePurpose = (
  application,
) => {
  if ((application.merge || application.merge === null)
    && (application.consolidationAmount === application.totalAmount)) {
    return 'consolidateLoans';
  } if (application.purpose !== null && typeof application.purpose !== 'undefined') {
    return application.purpose;
  }
  return 'everydayExpenses';
};

const submit = (application, visibleFields) => ({
  inputSource: application.source,
  totalAmount: String(parseInt(stripDelimiter(application.totalAmount), 10)),
  newLoan: calculateNewLoan(application),
  repaymentTime: String(parseInt(application.repaymentTime, 10) * 12),
  purpose: definePurpose(application),
  acceptNewsletter: application.acceptNewsletter,
  applicant: application.applicant
    ? submitApplicant(application.applicant, visibleFields) : null,
  coApplicant: application.coApplicant
    ? submitCoApplicant(application.coApplicant, visibleFields) : null,
  bankAccount: application.bankAccount,
  creditCheckConsent: application.creditCheckConsent || null,
  acceptAgreement: application.acceptAgreement || null,
  autoAccept: application.autoAccept || null,
});

export default submit;
