import React from 'react';
import styled from 'styled-components';
import { injectIntl, defineMessages } from 'react-intl';
import PropTypes from 'prop-types';
import ValidatedForm from '../molecules/ValidatedForm';
import style from './style';

const Wrapper = styled.div`
  && {
      margin: 1rem;
      background: ${style.autoAcceptBg};
      padding: 1rem;
      border-radius: 0.5rem;
     }
`;

const TitleWrapper = styled.div`
  && {
      display: flex;
    }  
`;

const Title = styled.h2`
  && {
     font-size: 1rem; 
     color: ${style.autoAcceptColor};
    }  
`;

const TextWrapper = styled.div`
  && {
      font-size: 1rem; 
      margin: 1rem;
    }  
`;

const ToggleWrapper = styled.div`
  && {
    display: flex;
    margin-top: ${style.AutoAcceptCheckpoxMarginTop};
    margin-left: 1rem;
  }  
`;

const CheckBoxWrap = styled.div`
  &&{
    margin-top: -1rem;
    font-weight: bolder;
  }
`;

const messages = defineMessages({
  title: {
    id: 'autoAccept.title',
    defaultMessage: 'Need Bid Assistance?',
    description: 'title for auto accept',
  },
  text: {
    id: 'autoAccept.text',
    defaultMessage: "We'll then help you with choosing the most suitable offer for you based on your application, including the requested loan amount, repayment period, lowest annual interest rate and smallest monthly installment. This selection will occur within 20 minutes after you submit your application.",
    description: 'text for the auto accept',
  },
  enable: {
    id: 'autoAccept.enable',
    defaultMessage: 'Enable Smart Bid Assistance',
    description: 'text after auto accept checkbox',
  },
});

const AutoAccept = ({
  intl: { formatMessage },
  data,
}) => (
  <Wrapper>
    <TitleWrapper>
      <svg
        style={{
          float: 'left',
          width: '2rem',
          height: '2rem',
          marginTop: '0.5rem',
          marginRight: '0.5rem',
        }}
        viewBox="0 0 24 24"
      >
        <path
          fill={style.autoAcceptColor}
          d="M12 20C13.25 20 14.3125 19.5625 15.1875 18.6875C16.0625 17.8125 16.5 16.75 16.5 15.5C16.5 14.25 16.0625 13.1875 15.1875 12.3125C14.3125 11.4375 13.25 11 12 11C10.75 11 9.6875 11.4375 8.8125 12.3125C7.9375 13.1875 7.5 14.25 7.5 15.5C7.5 16.75 7.9375 17.8125 8.8125 18.6875C9.6875 19.5625 10.75 20 12 20ZM9.075 9.7C9.40833 9.51667 9.7625 9.37083 10.1375 9.2625C10.5125 9.15417 10.8917 9.08333 11.275 9.05L8.75 4H6.25L9.075 9.7ZM14.925 9.7L17.775 4H15.25L13.125 8.25L13.6 9.2C13.8333 9.26667 14.0583 9.3375 14.275 9.4125C14.4917 9.4875 14.7083 9.58333 14.925 9.7ZM6.4 18.8C6.11667 18.3167 5.89583 17.7958 5.7375 17.2375C5.57917 16.6792 5.5 16.1 5.5 15.5C5.5 14.9 5.57917 14.3208 5.7375 13.7625C5.89583 13.2042 6.11667 12.6833 6.4 12.2C5.7 12.4333 5.125 12.8458 4.675 13.4375C4.225 14.0292 4 14.7167 4 15.5C4 16.2833 4.225 16.9708 4.675 17.5625C5.125 18.1542 5.7 18.5667 6.4 18.8ZM17.6 18.8C18.3 18.5667 18.875 18.1542 19.325 17.5625C19.775 16.9708 20 16.2833 20 15.5C20 14.7167 19.775 14.0292 19.325 13.4375C18.875 12.8458 18.3 12.4333 17.6 12.2C17.8833 12.6833 18.1042 13.2042 18.2625 13.7625C18.4208 14.3208 18.5 14.9 18.5 15.5C18.5 16.1 18.4208 16.6792 18.2625 17.2375C18.1042 17.7958 17.8833 18.3167 17.6 18.8ZM12 22C11.3333 22 10.6958 21.9042 10.0875 21.7125C9.47917 21.5208 8.91667 21.2583 8.4 20.925C8.25 20.9583 8.1 20.9792 7.95 20.9875C7.8 20.9958 7.64167 21 7.475 21C5.95833 21 4.66667 20.4667 3.6 19.4C2.53333 18.3333 2 17.0417 2 15.525C2 14.075 2.48333 12.8333 3.45 11.8C4.41667 10.7667 5.60833 10.1917 7.025 10.075L3 2H10L12 6L14 2H21L17 10.025C18.4167 10.1583 19.6042 10.7417 20.5625 11.775C21.5208 12.8083 22 14.05 22 15.5C22 17.0333 21.4667 18.3333 20.4 19.4C19.3333 20.4667 18.0333 21 16.5 21C16.35 21 16.1958 20.9958 16.0375 20.9875C15.8792 20.9792 15.725 20.9583 15.575 20.925C15.0583 21.2583 14.5 21.5208 13.9 21.7125C13.3 21.9042 12.6667 22 12 22ZM10.15 18.25L10.85 15.975L9 14.65H11.275L12 12.25L12.725 14.65H15L13.15 15.975L13.85 18.25L12 16.85L10.15 18.25Z"
        />
      </svg>
      <Title>
        {' '}
        {formatMessage(messages.title)}
        {' '}
      </Title>
    </TitleWrapper>
    <TextWrapper>
      {formatMessage(messages.text)}
    </TextWrapper>
    <ToggleWrapper>
      <CheckBoxWrap>
        <ValidatedForm.Checkbox
          data={data}
          type="checkbox"
          checked
          label={messages.enable}
        />
      </CheckBoxWrap>
    </ToggleWrapper>
  </Wrapper>
);

AutoAccept.propTypes = {
  intl: PropTypes.shape(),
  data: PropTypes.shape(),
};

AutoAccept.defaultProps = {
  data: {},
  intl: {},
};

export default injectIntl(AutoAccept);
